import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import NightShelterIcon from "@mui/icons-material/NightShelter";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import "./menu.css";
import "../../amplify/config";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch } from "react-redux";
import revImage from "../../assets/revanista-logo.png";
import {
  Modal,
  TextField,
  Autocomplete,
  AutocompleteRenderInputParams,
} from "@mui/material";
import { addHotelId } from "../../Redux/Store/Slice/hotelSlice";
import { MobileApi } from "../../amplify/api";
import { MobileAuth } from "../../amplify/auth";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "1px solid var(--button-color);",
  boxShadow: 24,
  p: 4,
};

interface Hotel {
  hotelId: string;
  name: string;
}

export default function MainMenu() {
  const [hotels, setHotels] = React.useState<Hotel[]>([]);
  const [hotel, setHotel] = React.useState<string | null>(null);
  const [dropdownValue, setDropdownValue] = React.useState<{
    label: string;
    id: string;
    key: number;
  } | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [, setAllHotelData] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [showHotels, setShowHotels] = React.useState<boolean>(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleChangeHotel = (
    hotelName: { label: string; id: string; key: number } | null,
  ) => {
    if (hotelName) {
      setHotel(hotelName.id);
      setDropdownValue(hotelName);
    }
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    session();
    setHotelIdInRedux();
  }, []);

  const session = async () => {
    try {
      const userHotelIds = await MobileAuth.getUserHotelsPermissions();
      const userHotelId = JSON.parse(userHotelIds).admin;
      const hotelData = await MobileApi.getHotels();
      if (userHotelId[0] === "*") {
        setAllHotelData(hotelData ?? []);
        if (hotelData && hotelData.length > 0) {
          setHotel(hotelData[0].hotelId);
          setHotels(hotelData);
          setShowHotels(true);
        } else {
          console.log("No hotels found.");
        }
      } else if (userHotelId.length > 2) {
        setAllHotelData(hotelData); // Update state with the response
        setHotel(hotelData[0].hotelId);
        const displayedHotel = hotelData.filter((data: Hotel) =>
          userHotelId.includes(data.hotelId),
        );
        setHotels(displayedHotel);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.log("Error fetching hotels: ", error.message);
      } else if (
        typeof error === "object" &&
        error !== null &&
        "response" in error
      ) {
        console.log(
          "Error fetching hotels: ",
          // eslint-disable-next-line
          JSON.parse((error as any).response.body),
        );
      } else {
        console.log("An unknown error occurred");
      }
    }
  };

  React.useEffect(() => {
    setHotelIdInRedux();
    setShow(false);
  }, [hotel]);

  const setHotelIdInRedux = () => {
    const hotelIdInNumber = parseInt(hotel ?? "0");
    dispatch(addHotelId(hotelIdInNumber));
  };

  async function handleSignOut() {
    try {
      await MobileAuth.signOut();
      navigate("/signIn");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  const hotelName = hotels.map((data, key) => {
    return { label: data.name, id: data.hotelId, key: key };
  });

  return (
    <React.Fragment>
      <div className="menuHeader">
        <div className="revLogo">
          <img src={revImage} alt="revanista" className="revImg" id="revLogo" />
        </div>
        <div className="accountLogo">
          {showHotels === true && (
            <>
              <div>
                <Box>
                  <NightShelterIcon
                    className="HotelIcon"
                    style={{ marginTop: "5px", fontSize: "40px" }}
                    onClick={() => setShow(true)}
                    aria-label="Select Hotel"
                    id="home-icon"
                  />
                  <Modal
                    open={show}
                    onClose={() => setShow(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={ModalStyle}>
                      <Autocomplete
                        value={dropdownValue}
                        id="controllable-states-demo"
                        options={hotelName}
                        getOptionLabel={(option) => option.label || ""}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value?.id
                        }
                        sx={{ width: "100%" }}
                        onChange={(event, hotelName) =>
                          handleChangeHotel(hotelName)
                        }
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) =>
                          setInputValue(newInputValue)
                        }
                        renderInput={(
                          params: AutocompleteRenderInputParams,
                        ) => (
                          <TextField
                            name="hotelSearch"
                            {...params}
                            label="Select hotel"
                          />
                        )}
                      />
                    </Box>
                  </Modal>
                </Box>
              </div>
            </>
          )}
          <Box
            sx={{ display: "flex", alignItems: "right", textAlign: "right" }}
          >
            <Tooltip title="User">
              <IconButton onClick={handleClick} size="small">
                <Avatar sx={{ width: 32, height: 32 }} id="profile-icon">
                  <PersonIcon />
                </Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={handleSignOut}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Sign out
            </MenuItem>
          </Menu>
        </div>
      </div>
    </React.Fragment>
  );
}
