import { get, post, put } from "aws-amplify/api";
import { MobileAuth } from "./auth";
import type {
  HotelRate,
  OtaDemand,
  CompetitorHotel,
  PublishRates,
  RemoveSuggestedRate,
  AcceptSuggestedRate,
  SuggestedRate,
} from "./defs";

export class MobileApi {
  static async getHotels() {
    const rep = await get({
      apiName: "yield",
      path: `/hotels`,
      options: {
        headers: {
          Authorization: await MobileAuth.getAuthToken(),
        },
      },
    }).response;
    const text = await rep.body.text();
    const payload = JSON.parse(text);
    return payload;
  }

  static async publishrates(body: PublishRates) {
    return post({
      apiName: "yield",
      path: `/publishrates`,
      options: {
        body: { ...body },
        headers: {
          Authorization: await MobileAuth.getAuthToken(),
        },
      },
    });
  }

  static async removeSuggRate(body: RemoveSuggestedRate) {
    return post({
      apiName: "yield",
      path: `/removeSuggestedRate`,
      options: {
        body: { ...body },
        headers: {
          Authorization: await MobileAuth.getAuthToken(),
        },
      },
    });
  }

  static async acceptSuggestedRate(body: AcceptSuggestedRate) {
    return post({
      apiName: "yield",
      path: `/acceptSuggestedRate`,
      options: {
        body: { ...body },
        headers: {
          Authorization: await MobileAuth.getAuthToken(),
        },
      },
    });
  }

  static async suggestRate(body: SuggestedRate) {
    return put({
      apiName: "yield",
      path: `/suggestedrate`,
      options: {
        body: { ...body },
        headers: {
          Authorization: await MobileAuth.getAuthToken(),
        },
      },
    });
  }

  static async getSuggDays(
    currentHotelId: number,
    currentDate: string,
    twoYear: string,
  ) {
    const rep = await get({
      apiName: "yield",
      path: `/suggDays/${currentHotelId}/${currentDate}/${twoYear}`,
      options: {
        headers: {
          Authorization: await MobileAuth.getAuthToken(),
        },
      },
    }).response;
    const text = await rep.body.text();
    const payload = JSON.parse(text);
    return payload;
  }

  static async getHotelConfig(hotelId: number) {
    const rep = await get({
      apiName: "yield",
      path: `/hotel/${hotelId}`,
      options: {
        headers: {
          Authorization: await MobileAuth.getAuthToken(),
        },
      },
    }).response;
    const text = await rep.body.text();
    const payload = JSON.parse(text);
    return payload;
  }

  static async getDemandFromOTATable(
    otaHotelId: number,
    startDate: string,
    endDate: string,
  ): Promise<Record<string, OtaDemand>> {
    const rep = await get({
      apiName: "yield",
      path: `/ota/${otaHotelId}/otaDemand/${startDate}/${endDate}`,
      options: {
        headers: {
          Authorization: await MobileAuth.getAuthToken(),
        },
      },
    }).response;
    const text = await rep.body.text();
    const payload = JSON.parse(text);
    return payload;
  }

  static async getOtaHotelCompList(
    otaHotelId: number,
  ): Promise<CompetitorHotel[]> {
    const rep = await get({
      apiName: "yield",
      path: `/ota/${otaHotelId}/competitors`,
      options: {
        headers: {
          Authorization: await MobileAuth.getAuthToken(),
        },
      },
    }).response;
    const text = await rep.body.text();
    const payload = JSON.parse(text);
    return payload;
  }

  static async getRatesFromOTATable(
    otaHotelId: number,
    startDate: string,
    endDate: string,
  ): Promise<Record<string, HotelRate>> {
    const rep = await get({
      apiName: "yield",
      path: `/ota/${otaHotelId}/otaRates/${startDate}/${endDate}`,
      options: {
        headers: {
          Authorization: await MobileAuth.getAuthToken(),
        },
      },
    }).response;
    const text = await rep.body.text();
    const payload = JSON.parse(text);
    return payload;
  }
}
